<template>
    <div class="pbuy">
        <h1 v-html="$content(`pbuy_${params.operation}_title`)"></h1>
        <p v-show="params.operation === 'buy'" v-html="$content(`pbuy_${params.operation}_desc`)"></p>
        <div class="pbuy-body">
            <button class="confirm-button" @click="$modal.close()">{{ $content('answer-cancel') }}</button>
            <button class="confirm-button confirm-button_app" @click="confirm">{{ $content('answer-confirm') }}</button>
        </div>
    </div>
</template>

<script>
export default {
    name: "PackageBuy",
    computed: {
        params() {
            return this.$store.getters.activeModalParams
        }
    },
    methods: {
        confirm() {
            switch (this.params.operation) {
                case "buy":
                    this.$http('shop').purchaseBuyWallet({id: this.params.id, agreetment: true})
                        .then(res => {
                            if (this.$payloadHandler(res).status === 'success') {
                                this.$root.$emit('packagePurchased');
                                this.$modal.close();
                            }
                        })
            }
        }
    }
}
</script>

<style lang="scss" scoped>
    @import "../../styles/mixins";

    .pbuy {
        background: white;
        border-radius: 10px;
        padding: 35px 15px;
        font-family: Montserrat-Medium, sans-serif;
        width: 600px;

        @include only-xs {
            width: 100%;
        }

        &-body {
            display: flex;
            justify-content: flex-end;
        }

        & h1 {
            font-size: 24px;
            color: var(--main-app);
            text-transform: uppercase;
        }

        & p {
            margin-bottom: 36px;
            color: var(--main-dark);
        }
    }
</style>